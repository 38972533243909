<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="employeeHeaders"
                    :loading="$store.getters['employee/loading']"
                    :items="$store.getters['employee/employees']"
                    :search="searchEmployee"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Employee List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Employee"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchEmployee"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <span v-if="item.status">Active</span>
                        <span v-if="!item.status">Inactive</span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="showEmployeeProfile(item)" color="primary" v-on="on">mdi-eye</v-icon>
							</template>
							<span>View</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteEmployee"></delete-confirm>
        <employee-profile :employee="employeeProfile" ref="employeeProfile"></employee-profile>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'
import EmployeeProfile from '@/components/EmployeeProfile'

export default {
    name: 'Employee',
    components: {
        DeleteConfirm,
        EmployeeProfile,
    },
    data: ()=> ({
        date: new Date().toISOString().substr(0, 10),
        statusList: [
            {val: 1, text: 'Active'}, 
            {val: 0, text: 'Inactive'}
        ],
        employeeHeaders: [
            { text: 'Emp. ID', value: 'code' },
            { text: 'Name', value: 'name' },
            { text: 'Designation', value: 'designation.name' },
            { text: 'Department', value: 'department.name' },
            { text: 'Joining Date', value: 'joining_date' },
            { text: 'Contact No', value: 'contact_number' },
            { text: 'Status', value: 'status' },
            { text: 'Action', value: 'action' },
        ],
        searchEmployee: '',
        employeeDeleteId: null,
        employeeProfile: {},
    }),
    created() {
        this.$store.dispatch('employee/getEmployees')
    },
    methods: {
        openDeleteDialog(id) {
            this.employeeDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteEmployee() {
            this.$store.dispatch('employee/deleteEmployee', {id: this.employeeDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.employeeDeleteId = null
        },

        showEmployeeProfile(profile) {
            this.employeeProfile = profile
            this.$refs.employeeProfile.dialog = true
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px;
    }
    .v-file-input .v-text-field__slot {
        min-height: 27px !important;
    }
    .e-image {
        .v-input__prepend-inner {
            margin-top: 0 !important;
        }
    }
</style>