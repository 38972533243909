<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Employee Profile</span>
        </v-card-title>
        <v-card-text>

          <v-container>
            <v-row>
                <v-col cols="3">
                    <v-img
                        v-if="employee.image != null"
                        :lazy-src="`${apiBasePath}/${employee.image}`"
                        height="100"
                        max-width="250"
                        :src="`${apiBasePath}/${employee.image}`"
                        contain=""
                    ></v-img>
                    <v-img
                        v-else
                        lazy-src="@/assets/no-image.jpg"
                        height="100"
                        max-width="250"
                        src="@/assets/no-image.jpg"
                        contain=""
                        style="border: 1px solid rgb(0 0 0 / 25%)"
                    ></v-img>
                </v-col>
                <v-col cols="9">
                    <table>
                        <tr>
                            <th>Emp. ID</th>
                            <td>:</td>
                            <td>{{ employee.code }}</td>
                        </tr>
                        <tr>
                            <th>Emp.Name</th>
                            <td>:</td>
                            <td>{{ employee.name }}</td>
                        </tr>
                        <tr>
                            <th>Department</th>
                            <td>:</td>
                            <td>{{ employee.department ? employee.department.name : '' }}</td>
                        </tr>
                        <tr>
                            <th>Designation</th>
                            <td>:</td>
                            <td>{{ employee.designation ? employee.designation.name : '' }}</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <table>
                        <tr>
                            <th>Father's Name</th>
                            <td>:</td>
                            <td>{{ employee.father_name }}</td>
                        </tr>
                        <tr>
                            <th>Mother's  Name</th>
                            <td>:</td>
                            <td>{{ employee.mother_name }}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>:</td>
                            <td>{{ employee.gender }}</td>
                        </tr>
                        <tr>
                            <th>Date of Birth</th>
                            <td>:</td>
                            <td>{{ employee.date_of_birth }}</td>
                        </tr>
                        <tr>
                            <th>Marital Status</th>
                            <td>:</td>
                            <td>{{ employee.marital_status }}</td>
                        </tr>
                        <tr>
                            <th>Pre. Address</th>
                            <td>:</td>
                            <td>{{ employee.present_address }}</td>
                        </tr>
                        <tr>
                            <th>Per. Address</th>
                            <td>:</td>
                            <td>{{ employee.permanent_address }}</td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="6">
                    <table>
                        <tr>
                            <th>Joining Date</th>
                            <td>:</td>
                            <td>{{ employee.joining_date }}</td>
                        </tr>
                        <tr>
                            <th>Salary Range</th>
                            <td>:</td>
                            <td>{{ employee.salary_range }}</td>
                        </tr>
                        <tr>
                            <th>Contact No</th>
                            <td>:</td>
                            <td>{{ employee.contact_number }}</td>
                        </tr>
                        <tr>
                            <th>E-Mail</th>
                            <td>:</td>
                            <td>{{ employee.email }}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>:</td>
                            <td>{{ employee.status ? 'Active' : 'Inactive' }}</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ["employee"],
    data: () => ({
      dialog: false,
      apiBasePath: window.apiBaseURL,
    }),
  }
</script>

<style lang="scss" scoped>
table {
    th {
        text-align: left;
    }
    th, td {
        padding: 0 5px;
    }
}
</style>