var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"custom-data-table elevation-1",attrs:{"dense":"","headers":_vm.employeeHeaders,"loading":_vm.$store.getters['employee/loading'],"items":_vm.$store.getters['employee/employees'],"search":_vm.searchEmployee},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","color":"white","elevation":1,"height":"30px"}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v("Employee List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-form',{staticClass:"custom-form"},[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search Employee","append-icon":"mdi-magnify"},model:{value:(_vm.searchEmployee),callback:function ($$v) {_vm.searchEmployee=$$v},expression:"searchEmployee"}})],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',[_vm._v("Active")]):_vm._e(),(!item.status)?_c('span',[_vm._v("Inactive")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.showEmployeeProfile(item)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.openDeleteDialog(item.id)}}},on),[_vm._v("mdi-delete-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)})],1)],1),_c('delete-confirm',{ref:"deleteDialog",on:{"confirm":_vm.deleteEmployee}}),_c('employee-profile',{ref:"employeeProfile",attrs:{"employee":_vm.employeeProfile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }